// shopify-api/cart.js
// Framework-agnostic utility functions for Shopify Cart API

/**
 * Configuration object for Shopify API
 */
const config = {
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_NAME,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  apiVersion: '2025-01', // Update this to the latest version you're using
}

/**
 * Create headers required for Shopify Storefront API requests
 */
const createShopifyHeaders = () => {
  return {
    'X-Shopify-Storefront-Access-Token': config.storefrontAccessToken,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
}
const CART_FRAGMENT = `
  fragment CartFields on Cart {
    id
    checkoutUrl
    discountCodes {
      code
      applicable
    }
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
    }
    buyerIdentity {
      email
      phone
      countryCode
    }
    attributes {
      key
      value
    }
    delivery {
      __typename
      addresses {
        id
        selected
        address {
          ... on CartDeliveryAddress {
            address1
            address2
            city
            company
            firstName
            lastName
            phone
            provinceCode
            zip
          }
        }
      }
    }
    deliveryGroups(first: 10) {
      edges {
        node {
          deliveryOptions {
            handle
            title
            estimatedCost {
              amount
              currencyCode
            }
          }
          selectedDeliveryOption {
            handle
            title
            estimatedCost {
              amount
              currencyCode
            }
          }
        }
      }
    }            
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          discountAllocations {
            discountedAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              image {
                url
                altText
              }
              selectedOptions {
                name
                value
              }
              sku
              price {
                amount
                currencyCode
              }
              product {
                title
                handle
              }
            }
          }
        }
      }
    }

    estimatedCost {
      totalAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
    }
  }
`
/**
 * Create a new cart
 * @returns {Promise<Object>} The created cart object
 */
export const createCart = async () => {
  try {
    const mutation = `
      ${CART_FRAGMENT}
      mutation cartCreate {
        cartCreate {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({ query: mutation }),
      }
    )

    const { data } = await response.json()

    if (data?.cartCreate?.userErrors?.length > 0) {
      throw new Error(data.cartCreate.userErrors[0].message)
    }

    return data?.cartCreate?.cart
  } catch (error) {
    throw error
  }
}
/**
 * Add items to cart
 * @param {string} cartId - The ID of the cart
 * @param {Array} lines - Array of merchandise lines to add
 * @returns {Promise<Object>} Updated cart
 */
export const addToCart = async (cartId, lines) => {
  try {
    // Validate inputs
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    if (!lines || !Array.isArray(lines) || lines.length === 0) {
      console.error('Invalid lines parameter:', lines)
      throw new Error('Lines must be a non-empty array')
    }

    const mutation = `
      ${CART_FRAGMENT}
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    // Format lines for the CartLineInput type
    const formattedLines = lines.map((line) => ({
      merchandiseId: line.merchandiseId,
      quantity: parseInt(line.quantity, 10), // Ensure quantity is an integer
    }))

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables: {
            cartId,
            lines: formattedLines,
          },
        }),
      }
    )

    const { data, errors } = await response.json()

    if (errors) {
      throw new Error(errors[0].message)
    }

    if (data?.cartLinesAdd?.userErrors?.length > 0) {
      throw new Error(data.cartLinesAdd.userErrors[0].message)
    }

    return data?.cartLinesAdd?.cart
  } catch (error) {
    throw error
  }
}
/**
 * Add or update items in cart
 * @param {string} cartId - The ID of the cart
 * @param {Array} lines - Array of merchandise lines to add
 * @returns {Promise<Object>} Updated cart
 */
export const updateCart = async (cartId, lines) => {
  try {
    // Validate inputs

    if (!cartId) {
      console.error('Missing cartId in updateCart')
      throw new Error('Cart ID is required')
    }

    if (!lines || !Array.isArray(lines) || lines.length === 0) {
      console.error('Invalid lines parameter:', lines)
      throw new Error('Lines must be a non-empty array')
    }

    const mutation = `
      ${CART_FRAGMENT}
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
           ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables: {
            cartId,
            lines: lines.map((line) => ({
              id: line.lineId, // The ID of the cart line to update
              quantity: line.quantity,
              merchandiseId: line.merchandiseId,
            })),
          },
        }),
      }
    )

    const { data, errors } = await response.json()

    if (errors) {
      throw new Error(errors[0].message)
    }

    if (data?.cartLinesUpdate?.userErrors?.length > 0) {
      throw new Error(data.cartLinesUpdate.userErrors[0].message)
    }

    return data?.cartLinesUpdate?.cart
  } catch (error) {
    console.error('Error updating cart:', error)
    throw error
  }
}

/**
 * Update cart items
 * @param {string} cartId - The ID of the cart
 * @param {Array} lines - Array of cart lines to update with quantities
 * @returns {Promise<Object>} Updated cart
 */

/**
 * Remove items from cart
 * @param {string} cartId - The ID of the cart
 * @param {Array} lineIds - Array of line IDs to remove
 * @returns {Promise<Object>} Updated cart
 */
export const removeFromCart = async (cartId, lineIds) => {
  try {
    // Validate inputs
    if (!cartId) {
      console.error('Missing cartId in removeFromCart')
      throw new Error('Cart ID is required')
    }

    if (!lineIds || !Array.isArray(lineIds) || lineIds.length === 0) {
      console.error('Invalid lineIds parameter:', lineIds)
      throw new Error('Line IDs must be a non-empty array')
    }

    const mutation = `
          ${CART_FRAGMENT}
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `
    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables: {
            cartId,
            lineIds,
          },
        }),
      }
    )

    // Check if the response is ok
    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    // Parse the response
    const responseData = await response.json()

    // Check for GraphQL errors
    if (responseData.errors && responseData.errors.length > 0) {
      console.error('GraphQL errors:', responseData.errors)
      throw new Error(`GraphQL error: ${responseData.errors[0].message}`)
    }

    // Check for user errors
    if (responseData.data?.cartLinesRemove?.userErrors?.length > 0) {
      console.error(
        'User errors:',
        responseData.data.cartLinesRemove.userErrors
      )
      throw new Error(responseData.data.cartLinesRemove.userErrors[0].message)
    }

    return responseData.data?.cartLinesRemove?.cart
  } catch (error) {
    console.error('Error removing from cart:', error)
    throw error
  }
}

/**
 * Fetch cart by ID
 * @param {string} cartId - The ID of the cart to fetch
 * @returns {Promise<Object>} Cart object
 */
export const getCart = async (cartId) => {
  try {
    const query = `
          ${CART_FRAGMENT}
      query getCart($cartId: ID!) {
        cart(id: $cartId) {
          ...CartFields
          checkoutUrl
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query,
          variables: {
            cartId,
          },
        }),
      }
    )

    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    const { data, errors } = await response.json()

    if (errors && errors.length > 0) {
      console.error('GraphQL errors:', errors)
      throw new Error(`GraphQL error: ${errors[0].message}`)
    }

    return data?.cart
  } catch (error) {
    console.error('Error fetching cart:', error)
    throw error
  }
}

/**
 * Apply a discount code to a cart
 * @param {string} cartId - The ID of the cart
 * @param {string} discountCode - The discount code to apply
 * @returns {Promise<Object>} Updated cart with applied discount
 */
export const applyDiscountCode = async (cartId, discountCode) => {
  try {
    // Validate inputs
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    if (!discountCode) {
      throw new Error('Discount code is required')
    }

    const mutation = `
      ${CART_FRAGMENT}
      mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
        cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
          cart {
            ...CartFields
    }
          userErrors {
            field
            message
          }
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables: {
            cartId,
            discountCodes: [discountCode],
          },
        }),
      }
    )

    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    const { data, errors } = await response.json()

    if (errors && errors.length > 0) {
      console.error('GraphQL errors:', errors)
      throw new Error(`GraphQL error: ${errors[0].message}`)
    }

    if (data?.cartDiscountCodesUpdate?.userErrors?.length > 0) {
      console.error('User errors:', data.cartDiscountCodesUpdate.userErrors)
      throw new Error(data.cartDiscountCodesUpdate.userErrors[0].message)
    }

    return data?.cartDiscountCodesUpdate?.cart
  } catch (error) {
    console.error('Error applying discount code:', error)
    throw error
  }
}

/**
 * Remove a discount code from a cart
 * @param {string} cartId - The ID of the cart
 * @returns {Promise<Object>} Updated cart with discount removed
 */
export const removeDiscountCode = async (cartId) => {
  try {
    // Validate inputs
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    const mutation = `
      ${CART_FRAGMENT}
      mutation cartDiscountCodesUpdate($cartId: ID!) {
        cartDiscountCodesUpdate(cartId: $cartId, discountCodes: []) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables: {
            cartId,
          },
        }),
      }
    )

    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    const { data, errors } = await response.json()

    if (errors && errors.length > 0) {
      console.error('GraphQL errors:', errors)
      throw new Error(`GraphQL error: ${errors[0].message}`)
    }

    if (data?.cartDiscountCodesUpdate?.userErrors?.length > 0) {
      console.error('User errors:', data.cartDiscountCodesUpdate.userErrors)
      throw new Error(data.cartDiscountCodesUpdate.userErrors[0].message)
    }

    return data?.cartDiscountCodesUpdate?.cart
  } catch (error) {
    console.error('Error removing discount code:', error)
    throw error
  }
}

/**
 * Proceed to checkout
 * @param {string} cartId - The ID of the cart to checkout
 * @returns {string} Checkout URL
 */
// export const gotToCheckout = async (cartId) => {
//   try {
//     // First fetch the current cart to get the checkoutUrl
//     const cart = await getCart(cartId)
//     return cart.checkoutUrl
//   } catch (error) {
//     console.error('Error proceeding to checkout:', error)
//     throw error
//   }
// }

/**
 * Update cart attributes (including buyer identity for email and marketing consent)
 * @param {string} cartId - The ID of the cart
 * @param {Object} attributes - Custom attributes to add to the cart
 * @param {Object} buyerIdentity - Buyer identity information including email
 * @returns {Promise<Object>} Updated cart
 */
export const updateAttributes = async (
  cartId,
  { attributes = [], buyerIdentity = {} }
) => {
  try {
    // Validate inputs
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    // Create our mutation string - we'll conditionally include sections based on what's provided
    let buyerIdentityInput = ''
    if (Object.keys(buyerIdentity).length > 0) {
      buyerIdentityInput = `
        buyerIdentity: {
          ${buyerIdentity.email ? `email: "${buyerIdentity.email}"` : ''}
          ${buyerIdentity.phone ? `, phone: "${buyerIdentity.phone}"` : ''}
          ${
            buyerIdentity.countryCode
              ? `, countryCode: ${buyerIdentity.countryCode}`
              : ''
          }
        }
      `
    }

    // Build the mutation
    const mutation = `
      ${CART_FRAGMENT}
      mutation cartAttributesUpdate(
        $cartId: ID!, 
        ${attributes.length > 0 ? '$attributes: [AttributeInput!]!' : ''}
      ) {
        cartAttributesUpdate(
          cartId: $cartId, 
          ${attributes.length > 0 ? 'attributes: $attributes' : ''}
          ${buyerIdentityInput ? buyerIdentityInput : ''}
        ) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    // Prepare variables for mutation
    const variables = {
      cartId,
      ...(attributes.length > 0 && { attributes }),
    }

    // Make API request
    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables,
        }),
      }
    )

    // Check if response is OK
    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    const responseData = await response.json()

    // Check for GraphQL errors
    if (responseData.errors && responseData.errors.length > 0) {
      console.error('GraphQL errors:', responseData.errors)
      throw new Error(`GraphQL error: ${responseData.errors[0].message}`)
    }

    // Check for user errors
    if (responseData.data?.cartAttributesUpdate?.userErrors?.length > 0) {
      console.error(
        'User errors:',
        responseData.data.cartAttributesUpdate.userErrors
      )
      throw new Error(
        responseData.data.cartAttributesUpdate.userErrors[0].message
      )
    }

    return responseData.data?.cartAttributesUpdate?.cart
  } catch (error) {
    console.error('Error updating cart attributes:', error)
    throw error
  }
}

/**
 * Update buyer identity (email, phone, etc.)
 * @param {string} cartId - The ID of the cart
 * @param {Object} buyerIdentity - Buyer identity object with email, phone, etc.
 * @returns {Promise<Object>} Updated cart
 */
export const updateBuyerIdentity = async (cartId, buyerIdentity) => {
  try {
    // Validate inputs
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    if (!buyerIdentity || Object.keys(buyerIdentity).length === 0) {
      throw new Error('Buyer identity information is required')
    }

    const mutation = `
      ${CART_FRAGMENT}
      mutation cartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
        cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables: {
            cartId,
            buyerIdentity,
          },
        }),
      }
    )

    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    const { data, errors } = await response.json()

    if (errors && errors.length > 0) {
      console.error('GraphQL errors:', errors)
      throw new Error(`GraphQL error: ${errors[0].message}`)
    }

    if (data?.cartBuyerIdentityUpdate?.userErrors?.length > 0) {
      console.error('User errors:', data.cartBuyerIdentityUpdate.userErrors)
      throw new Error(data.cartBuyerIdentityUpdate.userErrors[0].message)
    }

    return data?.cartBuyerIdentityUpdate?.cart
  } catch (error) {
    console.error('Error updating buyer identity:', error)
    throw error
  }
}

/**
 * Helper function to add or update cart attributes
 * @param {Array} existingAttributes - Current cart attributes
 * @param {Array} newAttributes - New attributes to add or update
 * @returns {Array} Merged attributes array
 */
export const mergeAttributes = (
  existingAttributes = [],
  newAttributes = []
) => {
  // Create a map of existing attributes by key
  const attributeMap = new Map()

  // Add existing attributes to the map
  existingAttributes.forEach((attr) => {
    attributeMap.set(attr.key, attr.value)
  })

  // Update or add new attributes
  newAttributes.forEach((attr) => {
    attributeMap.set(attr.key, attr.value)
  })

  // Convert map back to array of attribute objects
  return Array.from(attributeMap.entries()).map(([key, value]) => ({
    key,
    value,
  }))
}

/**
 * Update contact details including email and newsletter consent
 * @param {string} cartId - The ID of the cart
 * @param {Object} details - Contact details { email, newsletterConsent }
 * @returns {Promise<Object>} Updated cart
 */
export const updateContactDetails = async (
  cartId,
  { email, newsletterConsent, phone, countryCode }
) => {
  try {
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    if (!email) {
      throw new Error('Email is required')
    }

    // First, update the buyer identity with the email
    const buyerIdentityResponse = await updateBuyerIdentity(cartId, {
      email,
      phone,
    })

    // Then, update the custom attributes for newsletter consent
    const attributes = [
      {
        key: 'newsletterConsent',
        value: newsletterConsent ? 'true' : 'false',
      },
    ]

    const attributesResponse = await updateAttributes(cartId, {
      attributes,
    })

    // Return the final cart state
    return attributesResponse
  } catch (error) {
    console.error('Error updating contact details:', error)
    throw error
  }
}

// First, check if addresses exist using your getCart function

// Function to update an existing shipping address
export async function updateShippingAddress(cartId, addressData) {
  const mutation = `
    ${CART_FRAGMENT}
    mutation cartDeliveryAddressesUpdate($addresses: [CartSelectableAddressUpdateInput!]!, $cartId: ID!) {
      cartDeliveryAddressesUpdate(addresses: $addresses, cartId: $cartId) {
        cart {
          ...CartFields
        }
        userErrors {
          field
          message
        }
      }
    }
  `

  const variables = {
    addresses: [
      {
        address: {
          deliveryAddress: {
            address1: addressData.address1,
            address2: addressData.address2 || '',
            city: addressData.city,
            company: addressData.company || '',
            countryCode: addressData.countryCode,
            firstName: addressData.firstName,
            lastName: addressData.lastName,
            phone: addressData.phone,
            provinceCode: addressData.provinceCode,
            zip: addressData.zip,
          },
        },
        id: addressData.id, // The ID of the address to update
        selected: true,
      },
    ],
    cartId,
  }

  const response = await fetch(
    `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
    {
      method: 'POST',
      headers: createShopifyHeaders(),
      body: JSON.stringify({
        query: mutation,
        variables,
      }),
    }
  )

  const result = await response.json()

  if (
    result.errors ||
    result.data?.cartDeliveryAddressesUpdate?.userErrors?.length > 0
  ) {
    throw new Error(
      result.errors?.[0]?.message ||
        result.data?.cartDeliveryAddressesUpdate?.userErrors?.[0]?.message ||
        'Error updating shipping address'
    )
  }

  return result.data?.cartDeliveryAddressesUpdate.cart
}

export async function addShippingAddress(cartId, addressData) {
  const mutation = `
    ${CART_FRAGMENT}
    mutation cartDeliveryAddressesAdd($addresses: [CartSelectableAddressInput!]!, $cartId: ID!) {
      cartDeliveryAddressesAdd(addresses: $addresses, cartId: $cartId) {
        cart {
          ...CartFields
        }
        userErrors {
          field
          message
        }
      }
    }
  `

  const variables = {
    addresses: [
      {
        address: {
          deliveryAddress: {
            address1: addressData.address1,
            address2: addressData.address2 || '',
            city: addressData.city,
            company: addressData.company || '',
            countryCode: addressData.countryCode,
            firstName: addressData.firstName,
            lastName: addressData.lastName,
            phone: addressData.phone,
            provinceCode: addressData.provinceCode,
            zip: addressData.zip,
          },
        },
        selected: true,
      },
    ],
    cartId,
  }

  try {
    // Log the request for debugging

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables,
        }),
      }
    )

    // Check if the response is ok
    if (!response.ok) {
      const errorText = await response.text()
      console.error('HTTP error:', response.status, errorText)
      throw new Error(`HTTP error ${response.status}: ${errorText}`)
    }

    // Try to parse the response safely
    let result
    try {
      const text = await response.text()

      result = JSON.parse(text)
    } catch (parseError) {
      console.error('JSON parse error:', parseError)
      throw new Error(`Failed to parse response: ${parseError.message}`)
    }

    // Check for GraphQL errors
    if (
      result.errors ||
      result.data?.cartDeliveryAddressesAdd?.userErrors?.length > 0
    ) {
      const errorMessage =
        result.errors?.[0]?.message ||
        result.data?.cartDeliveryAddressesAdd?.userErrors?.[0]?.message ||
        'Error adding shipping address'
      console.error('GraphQL error:', errorMessage)
      throw new Error(errorMessage)
    }

    return result.data?.cartDeliveryAddressesAdd?.cart
  } catch (error) {
    console.error('Error in addCartShippingAddress:', error)
    throw error
  }
}

/**
 * Delete a cart
 * @param {string} cartId - The ID of the cart to delete
 * @returns {Promise<boolean>} Success status
 */
export const deleteCart = async (cartId) => {
  try {
    // Validate input
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    // Shopify doesn't have a direct "delete cart" mutation in the Storefront API
    // Instead, we'll use a workaround by removing all items from the cart

    // First, get the current cart to retrieve all line IDs
    const currentCart = await getCart(cartId)

    // If cart doesn't exist or is already empty
    if (
      !currentCart ||
      !currentCart.lines ||
      !currentCart.lines.edges ||
      currentCart.lines.edges.length === 0
    ) {
      return true
    }

    // Extract all line IDs
    const lineIds = currentCart.lines.edges.map((edge) => edge.node.id)

    // Remove all items from the cart
    const mutation = `
      ${CART_FRAGMENT}
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables: {
            cartId,
            lineIds,
          },
        }),
      }
    )

    // Check if the response is ok
    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    const responseData = await response.json()

    // Check for GraphQL errors
    if (responseData.errors && responseData.errors.length > 0) {
      console.error('GraphQL errors:', responseData.errors)
      throw new Error(`GraphQL error: ${responseData.errors[0].message}`)
    }

    // Check for user errors
    if (responseData.data?.cartLinesRemove?.userErrors?.length > 0) {
      console.error(
        'User errors:',
        responseData.data.cartLinesRemove.userErrors
      )
      throw new Error(responseData.data.cartLinesRemove.userErrors[0].message)
    }

    // After emptying the cart, you might want to remove it from local storage
    // This should be done in the component that calls this function

    return true
  } catch (error) {
    console.error('Error deleting cart:', error)
    throw error
  }
}

/**
 * Get available shipping rates for a cart
 * @param {string} cartId - The ID of the cart
 * @returns {Promise<Array>} Available shipping rates
 */
export const getAvailableShippingRates = async (cartId) => {
  try {
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    const query = `
      ${CART_FRAGMENT}
      query getCartDeliveryOptions($cartId: ID!) {
        cart(id: $cartId) {
          ...CartFields
          deliveryGroups {
            deliveryOptions {
              handle
              title
              estimatedCost {
                amount
                currencyCode
              }
            }
          }
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query,
          variables: {
            cartId,
          },
        }),
      }
    )

    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    const { data, errors } = await response.json()

    if (errors && errors.length > 0) {
      console.error('GraphQL errors:', errors)
      throw new Error(`GraphQL error: ${errors[0].message}`)
    }

    if (!data?.cart?.deliveryGroups) {
      return []
    }

    // Extract and format shipping options from all delivery groups
    const shippingRates = data.cart.deliveryGroups.flatMap((group) =>
      group.deliveryOptions.map((option) => ({
        handle: option.handle,
        title: option.title,
        price: {
          amount: option.estimatedCost.amount,
          currencyCode: option.estimatedCost.currencyCode,
        },
      }))
    )

    return shippingRates
  } catch (error) {
    console.error('Error fetching shipping rates:', error)
    throw error
  }
}

/**
 * Select a shipping option for the cart
 * @param {string} cartId - The ID of the cart
 * @param {string} deliveryOptionHandle - The handle of the selected delivery option
 * @returns {Promise<Object>} Updated cart
 */
export const selectShippingOption = async (cartId, deliveryOptionHandle) => {
  try {
    if (!cartId) {
      throw new Error('Cart ID is required')
    }

    if (!deliveryOptionHandle) {
      throw new Error('Delivery option handle is required')
    }

    const mutation = `
      ${CART_FRAGMENT}
      mutation cartDeliveryOptionUpdate($cartId: ID!, $deliveryOptionHandle: String!) {
        cartDeliveryOptionUpdate(cartId: $cartId, deliveryOptionHandle: $deliveryOptionHandle) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    const response = await fetch(
      `https://${config.storeDomain}/api/${config.apiVersion}/graphql.json`,
      {
        method: 'POST',
        headers: createShopifyHeaders(),
        body: JSON.stringify({
          query: mutation,
          variables: {
            cartId,
            deliveryOptionHandle,
          },
        }),
      }
    )

    if (!response.ok) {
      console.error(
        'API response not OK:',
        response.status,
        response.statusText
      )
      throw new Error(`API error: ${response.status} ${response.statusText}`)
    }

    const { data, errors } = await response.json()

    if (errors && errors.length > 0) {
      console.error('GraphQL errors:', errors)
      throw new Error(`GraphQL error: ${errors[0].message}`)
    }

    if (data?.cartDeliveryOptionUpdate?.userErrors?.length > 0) {
      console.error('User errors:', data.cartDeliveryOptionUpdate.userErrors)
      throw new Error(data.cartDeliveryOptionUpdate.userErrors[0].message)
    }

    return data?.cartDeliveryOptionUpdate?.cart
  } catch (error) {
    console.error('Error selecting shipping option:', error)
    throw error
  }
}
