import { useEffect, useMemo, useState } from 'react'

export function idOrRef(obj) {
  return obj?._id || obj?._ref
}

function scopeMatchFunc(limitBy) {
  return (item) => {
    // Group the productScope by type
    const idsByType = (item.productScope || []).reduce(
      (acc, { _type, ...scope }) => ({
        ...acc,
        [_type]: acc[_type]
          ? [...acc[_type], idOrRef(scope)]
          : [idOrRef(scope)],
      }),
      {}
    )

    // Returns true if at least one scope for each type, was also present in +limitBy+
    return Object.values(idsByType).every((ids) => {
      return limitBy?.find && limitBy.find((s) => ids.indexOf(idOrRef(s)) > -1)
    })
  }
}

export function getAllScoped(selectFrom, limitBy) {
  if (selectFrom?.filter) {
    return selectFrom.filter(scopeMatchFunc(limitBy))
  }
  return []
}

export function getFirstScoped(selectFrom, limitBy) {
  if (selectFrom?.find) {
    return selectFrom.find(scopeMatchFunc(limitBy))
  }
  return null
}

export function useExtRefsByShopName(productVariant) {
  return useMemo(() => {
    if (productVariant?.externalReferences) {
      return productVariant.externalReferences.reduce(
        (acc, ref) => ({ ...acc, [ref.shopName]: ref }),
        {}
      )
    }
    return {}
  }, [productVariant])
}

export function useProductScope(product, productColorway) {
  const {
    // productSizes must have at least the following attributes loaded for this function to work
    //
    //  - productSize.(_id or _ref)
    //  - productSize.position
    productSizes,

    // productVariants must have at least the following attributes loaded for this function to work
    //
    //  - productVariant.productColorway.(_id or _ref)
    //  - productVariant.productSize.(_id or _ref)
    productVariants,

    // === OPTIONAL
    // productImages must have at least the following attributes latead of this function to work
    //
    //  - productImage.productScope[]._type
    //  - productImage.productScope[].(_id or _ref)
    productImages,
  } = product

  // Cached memoization based on product props
  const possibleProductVariants = useMemo(
    () =>
      productVariants.filter(
        (v) => idOrRef(v.productColorway) === idOrRef(productColorway)
      ),
    [productVariants, productColorway]
  )

  // Calculate possible sizes based on the selected color and which size
  // is the first in order that is still in stock.
  const [firstInStockSize, possibleProductSizes] = useMemo(() => {
    const productVariantSizeIds = possibleProductVariants.map((v) =>
      idOrRef(v.productSize)
    )
    const firstInStockVariant = possibleProductVariants.find(
      (v) => v.inventoryStatus === 'in-stock'
    )
    const firstInStockSize =
      firstInStockVariant &&
      productSizes.find(
        (s) => idOrRef(firstInStockVariant.productSize) === idOrRef(s)
      )
    return [
      firstInStockSize,
      productSizes.filter(
        (s) => productVariantSizeIds.indexOf(idOrRef(s)) >= 0
      ),
    ]
  }, [possibleProductVariants, productSizes])

  const [selectedSize, setSelectedSize] = useState(
    firstInStockSize || possibleProductSizes[0] || null
  )
  const [selectedVariant, setSelectedVariant] = useState(null)

  const allProductSizesInOrder = useMemo(
    () => productSizes.sort((a, b) => a.position - b.position),
    [productSizes]
  )

  useEffect(() => {
    setSelectedVariant(
      productVariants.find(
        (v) =>
          idOrRef(v.productSize) === idOrRef(selectedSize) &&
          idOrRef(v.productColorway) === idOrRef(productColorway)
      )
    )
  }, [productVariants, productColorway, selectedSize])

  // Cached productPhotos that applies to the selected variant
  const scopedProductPhotos = useMemo(
    () =>
      getAllScoped(productImages, [productColorway])
        .map((s) => s.productPhoto)
        .filter(Boolean),
    [productImages, productColorway]
  )

  const allOutOfStock = useMemo(
    () =>
      possibleProductVariants.every(
        (productVariant) =>
          (productVariant.inventoryStatus || 'out-of-stock') === 'out-of-stock'
      ),
    [possibleProductVariants]
  )

  const extRefsByShop = useExtRefsByShopName(selectedVariant)

  return {
    allOutOfStock,
    allProductSizesInOrder,
    extRefsByShop,
    possibleProductSizes,
    possibleProductVariants,
    scopedProductPhotos,
    selectedSize,
    setSelectedSize,
    selectedVariant,
  }
}

export function useProductDescriptionsAndCareInstructions(
  product,
  productColorway
) {
  const {
    // productDescriptions must have at least the following attributes latead of this function to work
    //
    //  - productImage.productScope[]._type
    //  - productImage.productScope[].(_id or _ref)
    productDescriptions,

    // careInstructions must have at least the following attributes latead of this function to work
    //
    //  - careInstruction.productScope[]._type
    //  - careInstruction.productScope[].(_id or _ref)
    careInstructions,
  } = product

  // Cached productDescriptions that applies to the selected variant
  const scopedProductDescriptionSet = useMemo(
    () =>
      getFirstScoped(productDescriptions, [productColorway])
        ?.productDescriptionSet,
    [productDescriptions, productColorway]
  )

  // Cached careInstructions that applies to the selected variant
  const scopedCareInstructionSet = useMemo(
    () =>
      getFirstScoped(careInstructions, [productColorway])?.careInstructionSet,
    [careInstructions, productColorway]
  )

  return {
    scopedProductDescriptionSet,
    scopedCareInstructionSet,
  }
}

export function useProductPhotoScope(
  product,
  productColorway,
  noOfPhotos = null
) {
  const {
    // productImages must have at least the following attributes latead of this function to work
    //
    //  - productImage.productScope[]._type
    //  - productImage.productScope[].(_id or _ref)
    productImages,
  } = product

  // Cached productPhotos that applies to the selected variant
  const scopedProductPhotos = useMemo(() => {
    const photos = getAllScoped(productImages, [productColorway])
      .map((s) => s.productPhoto)
      .filter(Boolean)
    return noOfPhotos ? photos.slice(0, noOfPhotos) : photos
  }, [productImages, productColorway, noOfPhotos])

  return {
    scopedProductPhotos,
  }
}

export function useBestMatchProductImage(
  productImages,
  matchFields,
  notOneOf = null
) {
  return useMemo(() => {
    const images = productImages.filter(
      (i) => !notOneOf || notOneOf.indexOf(i) === -1
    )
    if (images.length === 0) return null

    return images.find((i) =>
      Object.keys(matchFields).every((f) => i[f] == matchFields[f])
    )

    // const productImageScores = images.map(productImage => [productImage.features.reduce((acc, f) => acc + scoring[f]), productImage], 0);
    // return productImageScores.sort(([scoreA], [scoreB]) => scoreB - scoreA)[0][1];
  }, [productImages, matchFields])
}

export const useCurrencyPrice = (price, currencyCode) => {
  if (currencyCode === 'EUR') {
    return price / 10
  }
  return price
}
