import React, { useCallback, useMemo } from 'react'
import { FaTrash } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'

import { useShopify } from '../../utils/shopify-api/shopifyContext'
import { useCountryStateContext } from '../../contexts/CountryContext'
import Price from '../../components/Price'

import QuantitySelector from './QuantitySelector'
import { useCurrencyPrice } from '../../utils/productScope'

const LineItem = (props) => {
  const { lineItem, product, productVariant } = props
  const { variant, quantity } = lineItem || {}
  const { selectedOptions = [], sku } = variant || {}

  const { cart, handleRemoveFromCart, handleUpdateCart } = useShopify()
  const { currencyCode } = useCountryStateContext()

  const [name, description] = useMemo(() => lineItem?.title?.split(' - '), [
    lineItem.title,
  ])
  const colorLabel = useMemo(
    () => selectedOptions.find((o) => o.name === 'Colorway')?.value || null,
    [selectedOptions]
  )

  const sizeLabel = useMemo(
    () => selectedOptions.find((o) => o.name === 'Size')?.value || null,
    [selectedOptions]
  )

  const handleQuantityChange = useCallback(
    async (updQty, adjustment) => {
      try {
        const evtName = adjustment < 0 ? 'remove' : 'add'
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(function () {
          this.reset()
        })
        window.dataLayer.push({
          event: `eec.${evtName}`,
          ecommerce: {
            currencyCode,
            [evtName]: {
              actionField: {
                list: 'Shopping Cart',
              },
              products: [
                {
                  id: lineItem.variant.sku,
                  name: `${lineItem.title} / ${lineItem.variant.title}`,
                  quantity: Math.abs(adjustment),
                  dimension2: lineItem.variant.selectedOptions[0]?.value || '',
                  dimension3: lineItem.variant.selectedOptions[1]?.value || '',
                },
              ],
            },
          },
        })

        // If quantity is 0, we should remove the item
        if (updQty === 0) {
          // Use removeFromCart if available, or set quantity to 0
          if (typeof handleRemoveFromCart === 'function') {
            await handleRemoveFromCart(lineItem.id)
          } else {
            await handleUpdateCart(lineItem.id, 0)
          }
        } else {
          // For updates with non-zero quantity
          await handleUpdateCart(lineItem.id, updQty)
        }
      } catch (e) {
        console.log('Error updating cart:', e)
      }
    },
    [lineItem, handleUpdateCart, handleRemoveFromCart]
  )

  return (
    <div className="flex pb-2 mb-8">
      <div className="w-24 h-32 mr-2 flex-initial">
        <img
          src={lineItem.variant.image?.src}
          className="w-24 h-32 object-cover"
          alt={lineItem.title}
        />
      </div>

      <div className="flex-1">
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm averta-bold leading-tight">{name}</h3>
            <p className="text-sm pb-1 leading-tight">
              {description}
              <br />
              <FormattedMessage
                id="shoppingCart.option-labels"
                description="Describes the color and size of the product"
                values={{
                  colorLabel,
                  sizeLabel,
                }}
              />
            </p>

            <p className="pb-1 text-sm">{sku}</p>

            <div className="pb-2 text-sm">
              {variant.price ? (
                <Price
                  amount={variant.price.amount}
                  currencyCode={currencyCode}
                  recalculateForVAT={true}
                />
              ) : (
                'N/A'
              )}
            </div>
          </div>

          <div className="flex-initial text-right">
            <button
              className="text-gray-500 hover:text-depalmaRed"
              onClick={() => handleQuantityChange(0, -quantity)} // Remove item by setting quantity to 0
            >
              <FaTrash />
            </button>
          </div>
        </div>

        <div className="flex justify-between">
          <QuantitySelector
            disabled={cart?.cartRequest}
            onChange={handleQuantityChange}
            quantity={quantity}
          />

          {variant.price ? (
            <Price
              amount={variant.price.amount * quantity}
              currencyCode={currencyCode}
              recalculateForVAT={true}
            />
          ) : (
            'N/A'
          )}
        </div>
      </div>
    </div>
  )
}

export default LineItem
