// ShopifyContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'

// Import your Shopify API functions
import {
  addShippingAddress,
  addToCart,
  applyDiscountCode,
  createCart,
  deleteCart,
  getAvailableShippingRates,
  getCart,
  mergeAttributes,
  removeDiscountCode,
  selectShippingOption,
  updateBuyerIdentity,
  updateAttributes,
  updateContactDetails,
  updateShippingAddress,
  updateCart,
  removeFromCart,
} from './shopifyApi'

import { useCountryStateContext } from '../../contexts/CountryContext'

// Create the context
const ShopifyContext = createContext(null)

// Custom hook for using the context
export const useShopify = () => {
  const context = useContext(ShopifyContext)
  if (!context) {
    throw new Error('useShopify must be used within a ShopifyProvider')
  }
  return context
}

// Provider component
export const ShopifyProvider = ({ children }) => {
  const [cart, setCart] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [consents, setConsents] = useState({})
  const [userErrors, setUserErrors] = useState([])
  const [formattedLineItems, setFormattedLineItems] = useState({})
  const { currencyCode, country } = useCountryStateContext()

  useEffect(() => {
    if (cart && cart.lines && cart.lines.edges && cart.lines.edges.length > 0) {
      const formatted = cart.lines.edges.map(({ node }) => ({
        id: node.id,
        quantity: node.quantity,
        variant: {
          id: node.merchandise.id,
          sku: node.merchandise.sku, // Extract SKU from Shopify Global ID
          title: node.merchandise.title,
          price: node.merchandise.price,
          product: node.merchandise.product,
          selectedOptions: node.merchandise.selectedOptions,
          image: {
            src: node.merchandise.image?.url || '',
            alt:
              node.merchandise.image?.altText || node.merchandise.product.title,
          },
        },
        title: node.merchandise.product.title,
      }))

      setFormattedLineItems(formatted)
    } else {
      setFormattedLineItems([])
    }
  }, [cart])

  // Initialize cart on first load
  useEffect(() => {
    const initializeCart = async () => {
      try {
        // Check if we have a cart ID stored in localStorage
        const storedCartId = localStorage.getItem('shopifyCartId')

        if (storedCartId) {
          // If we have one, we'll want to fetch the current cart state
          const cartData = await getCart(storedCartId)
          if (cartData === null) {
            localStorage.removeItem('shopifyCartId')
            initializeCart()
            return
          }
          setCart(cartData)
        } else {
          // Create a new cart
          setIsLoading(true)
          const newCart = await createCart()
          setCart(newCart)
          // Store the cart ID for future sessions
          localStorage.setItem('shopifyCartId', newCart.id)
        }
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    initializeCart()
  }, [])

  // Function to update an item in the cart
  const handleUpdateCart = async (lineId, quantity = 1) => {
    setIsLoading(true)
    setError(null)

    try {
      // Validate cart exists

      if (!cart || !cart.id) {
        throw new Error('Cart not initialized')
      }

      // Ensure quantity is a number
      const numQuantity = parseInt(quantity, 10)

      // Prepare lines input for update - make sure it matches the expected structure
      const lines = [
        {
          lineId, // This should be the cart line ID, not variant ID
          quantity: numQuantity,
        },
      ]

      // Call API function
      const updatedCart = await updateCart(cart.id, lines)

      // Validate response
      if (!updatedCart) {
        throw new Error('Failed to update cart - no data returned')
      }

      // Update local state
      setCart(updatedCart)

      return updatedCart
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  // Function to add a new item to the cart
  const handleAddToCart = async (variantId, quantity = 1) => {
    setIsLoading(true)
    setError(null)

    try {
      // Validate cart exists
      if (!cart || !cart.id) {
        throw new Error('Cart not initialized')
      }

      // Format variant ID if needed
      let formattedVariantId = variantId
      if (!String(variantId).includes('gid://')) {
        formattedVariantId = `gid://shopify/ProductVariant/${variantId}`
      }

      // Prepare lines input for new items
      const lines = [
        {
          merchandiseId: formattedVariantId,
          quantity,
        },
      ]

      // Call API function
      const updatedCart = await addToCart(cart.id, lines)

      // Validate response
      if (!updatedCart) {
        throw new Error('Failed to add to cart - no data returned')
      }

      // Update local state
      setCart(updatedCart)

      return updatedCart
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  // Function to remove an item from the cart
  const handleRemoveFromCart = async (lineId) => {
    setIsLoading(true)
    setError(null)

    try {
      // Validate cart exists
      if (!cart || !cart.id) {
        throw new Error('Cart not initialized')
      }

      // Call API function
      const updatedCart = await removeFromCart(cart.id, [lineId])

      // Validate response
      if (!updatedCart) {
        throw new Error('Failed to remove from cart - no data returned')
      }

      // Update local state
      setCart(updatedCart)

      return updatedCart
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (country?.slug) {
      const updateCountry = async () => {
        if (cart?.id) {
          const currentCountryCode = cart.buyerIdentity?.countryCode || ''
          const newCountryCode = country.slug.current.toUpperCase()

          // Only proceed with country-specific updates if the country code actually changed
          const countryChanged = currentCountryCode !== newCountryCode

          const updatedBuyerIdentity = cart?.buyerIdentity
            ? {
                ...cart.buyerIdentity,
                countryCode: country.slug.current.toUpperCase(),
              }
            : { countryCode: country.slug.current.toUpperCase() }

          const addressData = {
            address1: '',
            address2: '',
            city: '',
            company: '',
            firstName: '',
            lastName: '',
            phone: '',
            provinceCode: '',
            zip: '',
            countryCode: country.slug.current.toUpperCase(),
          }
          try {
            // Check if there are any discount codes in the cart
            if (
              cart.discountCodes &&
              cart.discountCodes.length > 0 &&
              countryChanged
            ) {
              // Remove any existing discount codes when country changes
              await removeDiscountCode(cart.id)
            }

            if (cart?.delivery?.addresses?.length > 0) {
              const addressId = cart.delivery.addresses[0].id
              addressData.id = addressId
              await updateShippingAddress(cart.id, addressData)
            }
            const updatedCart = await updateBuyerIdentity(
              cart.id,
              updatedBuyerIdentity
            )
            setCart(updatedCart)
          } catch (err) {
            setError(err)
          }
        }
      }
      updateCountry()
    }
  }, [cart?.id, country])

  const handleUpdateContactDetails = useCallback(
    async ({ email, newsletterConsent }) => {
      // Input validation

      if (!email) return
      if (!cart || !cart.id) return
      if (isLoading) return // Don't start a new request if one is in progress

      setConsents({ email, newsletterConsent })
      setIsLoading(true)
      setError(null)

      try {
        // Call the API function to update contact details
        await updateContactDetails(cart.id, {
          ...cart.buyerIdentity,
          email,
          newsletterConsent,
        })

        const newAttributes = [
          {
            key: 'newsletterConsent',
            value: newsletterConsent ? 'true' : 'false',
          },
        ]

        // Merge with existing attributes
        const mergedAttributes = mergeAttributes(
          cart.attributes || [],
          newAttributes
        )

        // Update both buyer identity and attributes in one call
        const updatedCart = await updateAttributes(cart.id, {
          attributes: mergedAttributes,
        })
        // Update the cart state with the new data
        setCart(updatedCart)

        return updatedCart
      } catch (err) {
        setError(err)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [cart, isLoading]
  )
  const handleUpdateShippingAddress = useCallback(
    async (shippingAddress) => {
      // Input validation
      if (!cart || !cart.id) {
        return
      }

      if (isLoading) {
        return
      }

      setIsLoading(true)
      setError(null)
      const addressData = {
        address1: shippingAddress.address1,
        address2: shippingAddress.address2,
        city: shippingAddress.city,
        company: shippingAddress.company,
        countryCode: shippingAddress.country.toUpperCase(),
        firstName: shippingAddress.firstName,
        lastName: shippingAddress.lastName,
        phone: shippingAddress.phone,
        provinceCode: shippingAddress.state,
        zip: shippingAddress.zip,
      }

      try {
        if (shippingAddress.phone) {
          await updateBuyerIdentity(cart.id, {
            phone: shippingAddress.phone,
            // Keep the existing email if available
            email: cart.buyerIdentity?.email || undefined,
          })
        }

        // Check if the cart already has delivery addresses
        const existingAddresses = cart.delivery?.addresses || []
        let updatedCart
        if (existingAddresses.length > 0) {
          // If there's an existing address, update it instead of adding a new one
          const addressId = existingAddresses[0].id
          addressData.id = addressId // Add the ID to update the existing address
          updatedCart = await updateShippingAddress(cart.id, addressData)
        } else {
          // If no address exists, add a new one
          updatedCart = await addShippingAddress(cart.id, addressData)
        }

        // If VAT number is provided, add it as a custom attribute
        if (shippingAddress.vatNumber) {
          // Create an array of attributes to update
          const newAttributes = [
            {
              key: 'vatNumber',
              value: shippingAddress.vatNumber,
            },
          ]

          // Merge with existing attributes
          const mergedAttributes = mergeAttributes(
            cart.attributes || [],
            newAttributes
          )

          // Update cart attributes with all attributes including VAT number
          await updateAttributes(cart.id, { attributes: mergedAttributes })
        }

        if (updatedCart) {
          setCart(updatedCart)
          return updatedCart
        } else {
          throw new Error('No cart data returned from update')
        }
      } catch (err) {
        setError(err)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [cart, isLoading]
  )

  const handleGoToCheckout = async () => {
    if (!cart) {
      setError(new Error('Cart is not available'))
      return
    }

    if (!cart.checkoutUrl) {
      setError(new Error('Checkout URL not available'))
      return
    }

    window.location.href = cart.checkoutUrl
  }

  // Additional cart functions would go here:
  // - updateCartItem
  // - removeFromCart
  // - clearCart
  // etc.
  // In ShopifyProvider component
  const clearCart = async () => {
    if (!cart || !cart.id) {
      return
    }

    setIsLoading(true)
    try {
      await deleteCart(cart.id)

      // Remove cart ID from localStorage
      localStorage.removeItem('shopifyCartId')

      // Create a new cart
      const newCart = await createCart()
      setCart(newCart)

      // Store the new cart ID
      localStorage.setItem('shopifyCartId', newCart.id)

      return true
    } catch (err) {
      setError(err)
      return false
    } finally {
      setIsLoading(false)
    }
  }
  const getShippingRates = useCallback(async () => {
    if (!cart || !cart.id) {
      return []
    }

    setIsLoading(true)
    setError(null)

    try {
      const shippingRates = await getAvailableShippingRates(cart.id)
      return shippingRates
    } catch (err) {
      setError(err)
      return []
    } finally {
      setIsLoading(false)
    }
  }, [cart])

  const selectShippingRate = useCallback(
    async (deliveryOptionHandle) => {
      if (!cart || !cart.id) {
        return
      }

      if (!deliveryOptionHandle) {
        return
      }

      setIsLoading(true)
      setError(null)

      try {
        const updatedCart = await selectShippingOption(
          cart.id,
          deliveryOptionHandle
        )
        setCart(updatedCart)
        return updatedCart
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [cart]
  )
  const handleApplyDiscount = useCallback(
    async (discountCode) => {
      if (!cart || !cart.id) {
        return
      }

      setIsLoading(true)
      setError(null)

      try {
        const updatedCart = await applyDiscountCode(cart.id, discountCode)
        setCart(updatedCart)
        return updatedCart
      } catch (err) {
        setError(err)
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [cart]
  )

  const handleRemoveDiscount = useCallback(async () => {
    if (!cart || !cart.id) {
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      const updatedCart = await removeDiscountCode(cart.id)
      setCart(updatedCart)
      return updatedCart
    } catch (err) {
      setError(err)
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [cart])

  // And add these to your value object:
  const value = {
    cart,
    isLoading,
    error,
    formattedLineItems,
    handleAddToCart,
    handleUpdateCart,
    handleGoToCheckout,
    handleRemoveFromCart,
    handleUpdateContactDetails,
    handleUpdateShippingAddress,
    clearCart,
    getShippingRates,
    selectShippingRate,
    handleApplyDiscount,
    handleRemoveDiscount,
    checkoutUrl: cart?.checkoutUrl, // Direct link to Shopify checkout
  }

  return (
    <ShopifyContext.Provider value={value}>{children}</ShopifyContext.Provider>
  )
}
