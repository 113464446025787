import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCMSContext } from '@happy-rabbit/gatsby-sanity-cms'
import Menu from '../../components/Menu'
import { withNavs } from '../../queries'
import LineItem from './LineItem'
import { useShopify } from '../../utils/shopify-api/shopifyContext'

const CartLineItems = (props) => {
  const {
    allSanityNav: { edges: navEdges = [] },
    className = 'overflow-y-scroll flex-auto px-6',
    lineItems,
  } = props
  const { lang, site } = useCMSContext()
  const navsById = useMemo(
    () =>
      navEdges.reduce(
        (acc, { node: nav }) => ({ ...acc, [nav.navId]: nav }),
        {}
      ),
    [navEdges]
  )
  const emptyCartNav = navsById['empty-cart']
  const { formattedLineItems } = useShopify()

  return (
    <div className={className}>
      {formattedLineItems.length > 0 ? (
        formattedLineItems.map((lineItem) => (
          <LineItem key={lineItem.id} lineItem={lineItem} />
        ))
      ) : (
        <div>
          <p className="mb-8">
            <FormattedMessage
              id="shoppingCart.empty"
              defaultMessage="Your shopping bag is currently empty. If you are looking for somewhere to start, explore our news or or other popular categories!"
            />
          </p>

          <Menu
            itemClassName="hover:underline mb-2 block"
            nav={emptyCartNav}
            site={site}
            lang={lang}
          />
        </div>
      )}
    </div>
  )
}

export default withNavs(CartLineItems)
