import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import Price from '../../components/Price'
import { useCountryStateContext } from '../../contexts/CountryContext'
import { useShopify } from '../../utils/shopify-api/shopifyContext'

import PromoCode from './PromoCode'

const CartVAT = (props) => {
  const { cart, country, includeVAT, vatAmount, totalAmount } = props
  const { behaviour } = useCountryStateContext()
  const vatRate = country?.vatRate || 0

  const renderPrice = () => {
    // If VAT should not be included (company purchase)

    // If we have a delivery address, use the tax amount directly
    if (cart?.delivery?.addresses?.length > 0 && vatAmount) {
      return (
        <Price
          amount={vatAmount.amount}
          currencyCode={vatAmount.currencyCode}
        />
      )
    }

    if (!country || !totalAmount) return '-'

    const amount = totalAmount.amount - totalAmount.amount / (1 + vatRate)
    return <Price amount={amount} currencyCode={totalAmount.currencyCode} />
  }

  return (
    <div className="flex justify-between">
      <h3 className="averta-bold">
        <FormattedMessage
          id={
            behaviour.lastProfileSelected === 'company'
              ? 'shoppingCart.company-vat'
              : 'shoppingCart.vat'
          }
          defaultMessage="VAT ({vatRate}%)"
          values={{
            vatRate: (vatRate || 0) * 100,
          }}
        />
      </h3>
      <div className="text-right averta-bold">{renderPrice()}</div>
    </div>
  )
}

const CartTotals = ({ isShoppingBag = false }) => {
  const { cart } = useShopify()
  const { country, includeVAT } = useCountryStateContext()

  // const {
  //   shippingLine,
  //   lineItemsSubtotalPrice,
  //   subtotalPriceV2,
  //   totalPriceV2,
  //   totalTaxV2,
  // } = cart?.checkout || {}
  const subtotalAmount = cart?.estimatedCost?.subtotalAmount
  const totalAmount = cart?.estimatedCost?.totalAmount
  const totalTaxAmount = cart?.estimatedCost?.totalTaxAmount
  const hasDiscounts =
    cart?.discountAllocations && cart.discountAllocations.length > 0

  const discountedAmount = hasDiscounts
    ? cart.discountAllocations.reduce(
        (sum, allocation) =>
          sum + parseFloat(allocation.discountedAmount.amount),
        0
      )
    : 0
  // const renderShippingLine = useCallback((priceV2) => {
  //   if (parseFloat(priceV2.amount) === 0) {
  //     return <FormattedMessage id="checkout.free" defaultMessage="Free" />
  //   } else {
  //     return <Price {...shippingLine.priceV2} />
  //   }
  // })
  const renderShippingCost = () => {
    // Check if cart has delivery groups with a selected delivery option

    const deliveryGroup = cart?.deliveryGroups?.edges?.[0]?.node
    const selectedOption = deliveryGroup?.selectedDeliveryOption

    if (selectedOption) {
      // If there's a selected shipping option
      const amount = parseFloat(selectedOption.estimatedCost.amount)

      if (amount === 0) {
        return <FormattedMessage id="checkout.free" defaultMessage="Free" />
      } else {
        return (
          <Price
            amount={amount}
            currencyCode={selectedOption.estimatedCost.currencyCode}
          />
        )
      }
    }

    // If no shipping option is selected yet
    return '-'
  }

  return (
    <div className="text-sm">
      <div className="flex justify-between mb-6">
        <h3 className="averta-bold">
          <FormattedMessage
            id="shoppingCart.subtotal"
            defaultMessage="Subtotal"
          />
        </h3>
        <div className="text-right averta-bold">
          {subtotalAmount ? (
            <Price
              amount={subtotalAmount.amount}
              currencyCode={subtotalAmount.currencyCode}
              recalculateForVAT={true}
            />
          ) : (
            '-'
          )}
        </div>
      </div>
      {!isShoppingBag && (
        <div className="mb-6">
          <PromoCode includeVAT={includeVAT} />
        </div>
      )}

      {hasDiscounts && !isShoppingBag && (
        <div className="flex justify-between mb-6">
          <h3 className="averta-bold">
            <FormattedMessage
              id="shoppingCart.discount"
              defaultMessage="Discount"
            />
          </h3>
          <div className="text-right averta-bold">
            {discountedAmount ? (
              <Price
                amount={-discountedAmount} // Negative to show as a reduction
                currencyCode={totalAmount.currencyCode}
              />
            ) : (
              '-'
            )}
          </div>
        </div>
      )}
      {!isShoppingBag && (
        <div className="flex justify-between mb-6">
          <h3 className="averta-bold">
            <FormattedMessage
              id="shoppingCart.estimated-freight"
              defaultMessage="Estimated Freight"
            />
          </h3>
          <div className="text-right averta-bold">{renderShippingCost()}</div>
        </div>
      )}

      <div className="mb-6">
        <CartVAT
          cart={cart}
          vatAmount={totalTaxAmount}
          totalAmount={totalAmount}
          country={country}
          includeVAT={includeVAT}
        />
      </div>

      <div className="flex justify-between mb-6">
        <h3 className="averta-bold">
          <FormattedMessage id="shoppingCart.total" defaultMessage="Total" />
        </h3>
        <div className="text-right averta-bold">
          {totalAmount ? (
            <Price
              amount={totalAmount.amount}
              currencyCode={totalAmount.currencyCode}
              recalculateForVAT={false}
            />
          ) : (
            '-'
          )}
        </div>
      </div>
    </div>
  )
}

export default CartTotals
