import clsx from 'clsx'
import React from 'react'
import { useForm } from 'react-hook-form'
import { FaTrash } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'
import { Button } from '@happy-rabbit/component-library'
import Price from '../../components/Price'

import { useCartStateContext } from '../../contexts/CartContext'
import { useShopify } from '../../utils/shopify-api/shopifyContext'
import { applyCartDiscountCode } from '../../utils/shopify-api/shopifyApi'

const knownErrors = {
  'Discount code Unable to find a valid discount matching the code entered':
    'code-not-found',
}

const DiscountForm = (props) => {
  const { applyDiscount, cart, checkoutErrors } = props

  const { handleApplyDiscount } = useShopify()
  const { handleSubmit, register } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = (data) => {
    const { code } = data

    handleApplyDiscount(code)
  }

  return (
    <>
      <form className="flex" onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register('code')}
          className={clsx(
            'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
            { 'border-red-500': false }
          )}
          placeholder="Gift card or discount code"
          type="text"
        />
        <Button disabled={cart?.cartRequest} theme="black" type="submit">
          Apply
        </Button>
      </form>

      {checkoutErrors.discountCode
        ? checkoutErrors.discountCode.map((msg, i) => (
            <p key={i} className="text-sm text-depalmaRed italic">
              {knownErrors[msg] ? (
                <FormattedMessage
                  id={`checkout.promoCode.errors.${knownErrors[msg]}`}
                  defaultMessage={msg}
                />
              ) : (
                msg
              )}
            </p>
          ))
        : null}
    </>
  )
}

const PromoCode = (props) => {
  const { includeVAT } = props
  const {
    cart,
    applyDiscount,
    removeDiscount,
    checkoutErrors,
  } = useCartStateContext()
  const { newCart } = useShopify()

  const { subtotalPriceV2, totalTaxV2 } = cart?.checkout || {}

  return (
    <div className="averta-bold mb-6">
      {cart?.checkout?.discountApplications?.length ? (
        <div>
          <div className="flex justify-between">
            <h3 className="averta-bold">
              <FormattedMessage
                id="shoppingCart.after-discount"
                defaultMessage="After Discount"
              />
            </h3>
            <div className="text-right averta-bold">
              {subtotalPriceV2 ? (
                <Price
                  amount={
                    includeVAT
                      ? subtotalPriceV2.amount
                      : subtotalPriceV2.amount - totalTaxV2.amount
                  }
                  currencyCode={subtotalPriceV2.currencyCode}
                />
              ) : (
                '-'
              )}
            </div>
          </div>

          {cart.checkout.discountApplications.map((discount, i) => (
            <div key={i} className="flex justify-between averta">
              <div>
                {discount.code}
                {` (${discount.value.percentage}%)`}
              </div>

              <div className="text-right">
                <Button
                  className="text-depalmaGray hover:text-depalmaRed"
                  height={4}
                  onClick={removeDiscount}
                  theme="none"
                >
                  <FaTrash />
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <DiscountForm
          applyDiscount={applyDiscount}
          cart={cart}
          checkoutErrors={checkoutErrors}
        />
      )}
    </div>
  )
}

export default PromoCode
