import React, { useMemo } from 'react'
import { FormattedNumber, IntlProvider } from 'react-intl'
import { useCountryStateContext } from '../../contexts/CountryContext'

const localesByCurrency = {
  EUR: 'en',
  SEK: 'sv',
}

export const BasePrice = (props) => {
  const {
    amount,
    currencyCode,
    recalculateForVAT = false,
    includeVAT = true,
    vatRate = 0,
  } = props

  const displayAmount = useMemo(() => {
    if (recalculateForVAT && !includeVAT && vatRate) {
      return amount / (1 + vatRate)
    }
    return amount
  }, [vatRate, includeVAT, amount])

  return (
    <IntlProvider locale={localesByCurrency[currencyCode] || 'en'}>
      <FormattedNumber
        value={displayAmount}
        style="currency"
        currency={currencyCode}
        {...(includeVAT && parseInt(amount) === parseFloat(amount)
          ? { maximumFractionDigits: 0 }
          : null)}
      />
    </IntlProvider>
  )
}

const Price = (props) => {
  const { country, includeVAT } = useCountryStateContext()

  return (
    <BasePrice
      {...props}
      vatRate={country?.vatRate || 0}
      includeVAT={includeVAT}
    />
  )
}

export default Price
